
import request from '../utils/request'
//获取新闻列表
// export const getNewsList=(data)=>request.post('/r/sys/client/website/doc/page',data)
// 获取新闻详情
export const getNewsDetail=(data)=>request.post('r/sys/client/website/doc/details',data)

// 官网文档栏目-首页导航 等
export const offDocCol=(data)=>request.post('/r/sys/client/website/doc/col/page',data)

// 官网文档
export const offDoc=(data)=>request.post('/r/sys/client/website/doc/page',data)
  
  //获取新闻列表
  export const getNewsList=(params)=>request.get('/doc/newsList',params)
  // 获取新闻详情
  export const getNewsDetailList=(params)=>request.get('/doc/newsDetail',params)
  
