<template>
	<div class="container">
		<div id="header">
			<img class="openLogo" :src=" require('../assets/imgsM/class1.png')" alt="" @click="openMenu" />
			<!-- <img class="openLogo" :src="
          flag == true
            ? require('../assets/imgsM/class1.png')
            : require('../assets/imgsM/no.png')
        " alt="" @click="openMenu" /> -->
			<router-link to="/homeWap" style="font-size: 20px;">仟家惠官网</router-link>
			<div style="width: 100px;"> </div>
			<!-- <el-button class="login" type="danger" size="mini">登录</el-button> -->
			<div class="nav" v-if="menuShow">
				<div class="nav-item" v-for="item in navList" @click="toPage(item.colUri)" v-if="item.isShow==1">
					{{item.colName}}
				</div>
				<!-- <el-collapse v-model="activeName" accordion>
					<el-collapse-item :title="item.colName" :name="item.index" v-for="item in navList"
						:key="item.colId">
						<div @click="closeMenu" v-for="i in item.children" :key="i.colId">
							<router-link :to="i.colUri">{{i.colName}}</router-link>
						</div>
					</el-collapse-item>
				</el-collapse> -->
			</div>
		</div>
	</div>
</template>

<script>
	import {
		offDocCol
	} from "../api/news.js"
	export default {
		//配置移动端页面meta
		metaInfo() {
			return {
				meta: [{
					name: "viewport",
					content: "width=device-width,user-scalable=0,initial-acale=1.0,maximum-scale=1.0,",
				}, ],
			};
		},
		data() {
			return {
				activeName: "1",
				menuShow: false,
				navList: [],
				params: {
					page: {
						pageNum: 1,
						pageSize: 999999,
					},
					data: {
						orgFreeId: "b4f9a873030bf5c2ab153c522c5ce9a8",
						appFreeId: "b36e3f43ece73b62aa2dadde0b92e81d",
						colId: "4692153251984969728"
					},
				},
			};
		},
		created() {
			this.getOffDocCol();
		},
		methods: {
			// 调文档栏目的接口
			getOffDocCol() {
				offDocCol(this.params).then((res) => {
					this.navList = res.data.data[0].children[0].children;
					this.total = res.data.total;
					console.log("==this.navList--------------==:", res.data.data[0].children[0].children);
				}).catch((err) => {
					console.log(err)
				});
			},
			openMenu() {
				this.menuShow = !this.menuShow;
			},
			toPage(path) {
				this.$router.push({
					path
				});
				if(this.menuShow){
					this.menuShow =!this.menuShow;
				}else{
					this.menuShow=this.menuShow;
				}
			}
		},
	};
</script>

<style lang="less" scoped>
	html,
	body {
		margin: 0;
		padding: 0;
		border: none;
		// font-size: 2.67vw;
	}

	#header {
		width: 100vw;
		z-index: 100;
		// font-size: 2.4rem;
		height: 48pt;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		.login{
			margin-right: 20px;
		}

		img {
			margin-left: 20pt;
		}

		.logoimg {
			width: 11rem;
			margin-left: 14pt !important;
		}

		.openLogo {
			width: 2.5rem;
		}

		.nav {
			display: flex;
			justify-content: space-around;
			line-height: 60px;
			position: absolute;
			top: 48pt;
			width: 100vw;
			height: 60px;
			box-sizing: border-box;
			background: #000;
			opacity: 0.9;

			.nav-item {
				display: flex;
				font-size: 14px;
				color: #fff;
			}

			/deep/.el-collapse-item__header {
				padding-left: 1.5rem;
				font-size: 2rem;
				font-weight: bold;
			}

			a {
				padding-left: 1.5rem;
			}
		}
	}
</style>
