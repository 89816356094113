import axios from 'axios';
// import { reject, resolve } from "core-js/fn/promise";
import QS from "qs";
import myUtils from './util'
// axios.defaults.baseURL = "https://api.eshare168.com/";
axios.defaults.baseURL = "http://192.168.10.66:29000";
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] =
	"application/x-www-form-urlencoded";
axios.defaults.headers.get["Content-Type"] =
	"application/json";
// 请求拦截
axios.interceptors.request.use(
	(config) => {
		// if (config.method == 'get') {
		// 	config.headers['content-type'] = 'application/json'
		// } else {
		// 	config.headers['content-type'] = 'application/x-www-form-urlencoded'
		// }
		if(config.method == 'post') {
			config.headers['content-type'] = 'application/json'
		}
		config.headers['Access-Token'] = localStorage.getItem('token') || ''
		if(!myUtils.isEmpty(config.type)) {
			if(config.type=='saas') {
				config.baseURL = "https://api.eshare168.com";
			}
		}else{
			// config.baseURL = "http://192.168.10.66:29000";
			config.baseURL = "https://api.eshare168.com/";
		}
		return config;
	},
	(err) => {
		return Promise.reject(err);
	}
);
// 响应拦截
axios.interceptors.response.use(
	(response) => {
		if (response.status === 200) {
			console.log(response);
			return Promise.resolve(response);
		} else {
			return Promise.reject(response);
		}
	},
	(err) => {
		if (error.response.status) {
			return Promise.reject(err);
		}
	}
);

//封装请求
const request = {
	get(url, params) {
		return new Promise((resolve, reject) => {
			axios.get(url, {
					params
				})
				.then(res => {
					resolve(res)
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	post(url, data, type) {
		return new Promise((resolve, reject) => {
			// axios.post(url, QS.stringify(data))
			axios.post(url, data, type)
				.then(res => {
					// console.log(res)
					resolve(res)
				})
				.catch(err => {
					reject(err)
				})
		})
	}
}

//导出请求
export default request;
