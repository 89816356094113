<template>
	<header>
		<img class="logo" src="../assets/logo.png" alt="图片加载失败" @click="clickLink('old', home)" />
		<div class="list-box">
			<!-- <div @click="goHome" v-if="this.showAccout='false'"> 返回首页</div> -->
			<div @click="clickLink(index, item.colUri)" class="linkA" :class="index === current ? 'activeStyle' : ''"
				v-for="(item, index) in oldlist" :key="item.colId" v-if="item.isShow==1">
				{{ item.colName }}
			</div>
		</div>
		<!-- 右侧 -->
		<!-- <div class="right">
			<div class="tower" @click="toPage('tower')">控制台</div>
			<div class="people" style="justify-content: center" v-if="!this.$myUtils.isEmpty(this.infoNum)">
				<div class="avatar"></div>
				<span class="name">{{this.infoNum}}</span>
			</div>
			<div class="people" v-else>
				<div class="register" @click="toPage('register')">
					<img src="@/assets/pc/people.png" alt="" />
					<span>立即注册</span>
				</div>
				<div class="sign-in" @click="toPage('login')">登录</div>
			</div>
		</div> -->
	</header>
</template>

<script>
	import {
		offDocCol,
	} from "../api/news.js"
	import {
		userInfo
	} from "../api/api"
	export default {
		data() {
			return {
				current: 0,
				showAccout: true,
				home: {
					name: "首页",
					url: "/home"
				},
				list: [],
				oldlist: [],
				infoNum: '',
				params: {
					page: {
						pageNum: 1,
						pageSize: 999999,
					},
					data: {
						orgFreeId: "b4f9a873030bf5c2ab153c522c5ce9a8",
						appFreeId: "b36e3f43ece73b62aa2dadde0b92e81d",
						colId: "1759852039548047360",
					},
				},
				newList: []
			};
		},
		mounted() {
			// 判断是否登录了,没有登录就显示 注册 登录
			this.showAccout
			this.list = this.oldlist;
			//  去掉已选择的首页下划线样式
			this.$bus.$on("changeCurrent", (val) => {
				this.current = val;
				console.log("--------")
			});
			//  更换list,隐藏登录注册按钮
			this.$bus.$on("changeList", () => {
				this.showAccout = false;
				this.list = this.newList;
				this.current = 0;
				console.log("..........")
			});
		},
		created() {
			this.getOffDocCol()
			this.getUserInfo()
		},
		methods: {
			// 头部导航栏
			getOffDocCol() {
				offDocCol(this.params).then((res) => {
					this.oldlist = res.data.data[0].children[0].children;
					this.total = res.total;
				}).catch((err) => {
					console.log(err)
				});
			},
			// 控制台点击后头部
			getRight() {
				offDocCol(this.params).then((res) => {
					this.newList = this.oldlist = res.data.data[0].children[1].children;
					this.total = res.total;
				}).catch((err) => {
					console.log(err)
				});
			},
			clickLink(index, path) {
				if (index === "old") {
					this.getOffDocCol()
					this.list = this.oldlist;
					this.current = 0;
					this.$router.push({
						path: "/home"
					});
				} else {
					this.current = index;
				this.$router.push({
					path
				});
				}
			},
			toPage(type) {
				this.current = -1;
				if (type === "register") {
					this.$router.push({
						path: "/login/register"
					});
				} else if (type === "login") {
					this.$router.push({
						path: "/login/login"
					});
				} else if (type === "tower") {
					// 如果登录了就跳控制台,未登录就去登录页面
					// 如果登录了
					this.getRight()
					this.current = 0;
					this.showAccout = false
					this.list = this.newList
					this.$router.push({
						path: '/control/control'
					})
				}
			},
			setIndex(path) {
				this.current = this.oldlist.findIndex((item) => item.colUri == path)
			}
		},
	};
</script>

<style lang="less" scoped>
	header {
		width: 100%;
		height: 100px;
		display: grid;
		grid-template-columns: 1.4fr 1.5fr 0.5fr;
		place-content: center;

		.logo {
			width: 9rem;
			place-self: center;
			cursor: pointer;
		}

		.list-box {
			font-size: 16px;
			display: grid;
			align-items: center;
			grid-template-rows: repeat(1, 1fr);
			grid-template-columns: minmax(32px, 50px) repeat(6, 65px);
			grid-gap: 0 68px;
			font-weight: bold;

			.linkA {
				height: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}

			.activeStyle {
				color: #e21515;
				position: relative;
				cursor: pointer;
			}

			.activeStyle::after {
				content: "";
				/*单引号双引号都可以，但必须是英文*/
				width: 30px;
				height: 2px;
				/*下划线高度*/
				background: #e21515;
				/*下划线颜色*/
				position: absolute;
				bottom: -5px;
				left: 50%;
				transform: translateX(-50%);
				transition: all 0.8s;
				/*css动画效果，0.8秒完成*/
			}
		}

		.right {
			height: 100%;
			display: flex;
			align-items: center;

			.tower {
				margin-right: 20px;
				color: #1677ff;
				cursor: pointer;
			}

			.people {
				display: flex;
				align-items: center;

				.register {
					display: flex;
					align-items: center;
					font-size: 14px;
					cursor: pointer;
					margin-right: 20px;

					img {
						width: 18px;
						height: 18px;
						margin-right: 9px;
					}

					span {
						color: #999999;
					}
				}

				.sign-in {
					width: 68px;
					height: 32px;
					background: #ec414d;
					border-radius: 6px;
					line-height: 32px;
					text-align: center;
					color: white;
					cursor: pointer;
				}

				.avatar {
					width: 31px;
					height: 31px;
					background: #d9d9d9;
					border-radius: 100%;
					margin-right: 10px;
				}

				.name {
					color: #999;
				}
			}
		}
	}
</style>
